// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-issues-climate-environmental-justice-js": () => import("./../../../src/pages/issues/climate-environmental-justice.js" /* webpackChunkName: "component---src-pages-issues-climate-environmental-justice-js" */),
  "component---src-pages-issues-education-js": () => import("./../../../src/pages/issues/education.js" /* webpackChunkName: "component---src-pages-issues-education-js" */),
  "component---src-pages-issues-housing-housing-affordability-js": () => import("./../../../src/pages/issues/housing-housing-affordability.js" /* webpackChunkName: "component---src-pages-issues-housing-housing-affordability-js" */),
  "component---src-pages-issues-improve-quality-of-life-js": () => import("./../../../src/pages/issues/improve-quality-of-life.js" /* webpackChunkName: "component---src-pages-issues-improve-quality-of-life-js" */),
  "component---src-pages-issues-improve-quality-of-life-plan-js": () => import("./../../../src/pages/issues/improve-quality-of-life/plan.js" /* webpackChunkName: "component---src-pages-issues-improve-quality-of-life-plan-js" */),
  "component---src-pages-issues-invest-public-health-healthcare-js": () => import("./../../../src/pages/issues/invest-public-health-healthcare.js" /* webpackChunkName: "component---src-pages-issues-invest-public-health-healthcare-js" */),
  "component---src-pages-issues-jobs-economic-justice-js": () => import("./../../../src/pages/issues/jobs-economic-justice.js" /* webpackChunkName: "component---src-pages-issues-jobs-economic-justice-js" */),
  "component---src-pages-issues-js": () => import("./../../../src/pages/issues.js" /* webpackChunkName: "component---src-pages-issues-js" */),
  "component---src-pages-issues-small-business-economy-js": () => import("./../../../src/pages/issues/small-business-economy.js" /* webpackChunkName: "component---src-pages-issues-small-business-economy-js" */),
  "component---src-pages-issues-transportation-js": () => import("./../../../src/pages/issues/transportation.js" /* webpackChunkName: "component---src-pages-issues-transportation-js" */),
  "component---src-pages-landing-birthday-email-js": () => import("./../../../src/pages/landing/birthday-email.js" /* webpackChunkName: "component---src-pages-landing-birthday-email-js" */),
  "component---src-pages-landing-birthday-sms-js": () => import("./../../../src/pages/landing/birthday-sms.js" /* webpackChunkName: "component---src-pages-landing-birthday-sms-js" */),
  "component---src-pages-letter-from-john-js": () => import("./../../../src/pages/letter-from-john.js" /* webpackChunkName: "component---src-pages-letter-from-john-js" */),
  "component---src-pages-media-endorsements-js": () => import("./../../../src/pages/media/endorsements.js" /* webpackChunkName: "component---src-pages-media-endorsements-js" */),
  "component---src-pages-media-photos-js": () => import("./../../../src/pages/media/photos.js" /* webpackChunkName: "component---src-pages-media-photos-js" */),
  "component---src-pages-media-press-js": () => import("./../../../src/pages/media/press.js" /* webpackChunkName: "component---src-pages-media-press-js" */),
  "component---src-pages-meet-john-js": () => import("./../../../src/pages/meet-john.js" /* webpackChunkName: "component---src-pages-meet-john-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-splash-js": () => import("./../../../src/pages/splash.js" /* webpackChunkName: "component---src-pages-splash-js" */),
  "component---src-pages-volunteer-js": () => import("./../../../src/pages/volunteer.js" /* webpackChunkName: "component---src-pages-volunteer-js" */)
}

